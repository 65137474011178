import React from "react";
import { extend, Canvas } from "react-three-fiber";
import { Text } from "troika-three-text";

import "./styles.css";

extend({ Text });

function App() {

  return (
    <div>
      <Canvas
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        pixelRatio={window.devicePixelRatio}
      >
        <text 
          text={["Tensoft"]}
          position-z={-10}
          fontSize={3}
          maxWidth={6}
          lineHeight={2}
          anchorX="center"
          anchorY="middle"
          font="https://fonts.gstatic.com/s/orbitron/v9/yMJRMIlzdpvBhQQL_Qq7dys.woff"
        />
        <pointLight position={[-100, 0, -160]} />
        <pointLight position={[0, 0, -170]} />
        <pointLight position={[100, 0, -160]} />
      </Canvas>
    </div>
  );
}

export default App;
